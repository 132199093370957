import { useState } from "react";
import OrderProvider, { useOrderContext } from './context/OrderContext';

import Summary from './components/Summary';
import SummarySmall from './components/SummarySmall';
import ProductGroups from './components/ProductGroups';
import formatPrice from './utils/formatPrice';
import { SummaryContext } from "./context/SummaryContext";
import { PriceTypeContext } from "./context/PriceTypeContext";

import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';


function App() {

  const isMiddleScreenSize = window.innerWidth < 1201 && window.innerWidth > 768;
  const isMobile = window.innerWidth < 769;

  const { isLocked } = useOrderContext();
  const [isSummaryOpen, setSummaryOpen] = useState(false);
  const [isRightColumnOpen, setRightColumnOpen] = useState(false);
  const { quantities, getCart } = useOrderContext()
  const cart = getCart();

  const priceNav = Object.values(window.APP_SETTINGS?.portalSettings.PRICES || {});
  const activePriceTypes = priceNav.filter(({ show }) => show);

  const firstPriceType = activePriceTypes[0]?.price;

  const [priceType, setPriceType] = useState(activePriceTypes.length > 0 ? firstPriceType : "rrp");

  return (
    <div className="app">
      <SummaryContext.Provider value={{ isRightColumnOpen, setRightColumnOpen }}>
        <PriceTypeContext.Provider value={{ priceType, setPriceType }}>
          <div className="row">
            <header className="app-header">
              <img src={window.APP_SETTINGS?.portalSettings?.LOGO} className="app-logo-header" alt="logo" />
              <div className="app-header--wrapper">
                {activePriceTypes.length > 1 &&
                  <div class="btn-group app-price-buttons" role="group">
                    {activePriceTypes.map((item) => (
                        <button onClick={() => setPriceType(item.price)} type="button" className={priceType === item.price ? "btn-active btn btn-outline-primary" : "btn btn-outline-primary"}>{item.price}</button>
                      ))}
                  </div>
                }
                <div className="app-header-basket">
                  {cart.pcs > 0 &&
                    <p>{cart.pcs}pcs / {formatPrice(cart.sumWholesale)}</p>
                  }
                  {isMobile && <button className="app-header--summary" onClick={() => setSummaryOpen(!isSummaryOpen)}>{/*ORDER SUMMARY*/}</button>}
                  {isMiddleScreenSize && <button className={isRightColumnOpen ? "app-header--summary app-header--summary--active" : "app-header--summary"} onClick={() => setRightColumnOpen(!isRightColumnOpen)}>{/*isRightColumnOpen ? "CLOSE SUMMARY" : "ORDER SUMMARY"*/}</button>}
                </div>
              </div>
            </header>

            <div className={isRightColumnOpen ? "col-12 col-xl-8 app-content-wrapper app-content-wrapper--small" : "col-12 col-xl-8 app-content-wrapper"}>
              <div className="app-header-desktop">
                <img src={window.APP_SETTINGS?.portalSettings?.LOGO} className="app-logo" alt="logo" />
                {activePriceTypes.length > 1 &&
                  <div class="btn-group app-price-buttons" role="group">
                    {activePriceTypes.map((item, index) => (
                        <button onClick={() => setPriceType(item.price)} type="button" className={priceType === item.price ? "btn-active btn btn-outline-primary" : "btn btn-outline-primary"}>{item.price}</button>
                      ))}
                  </div>
                }
              </div>
              <section className={`app-content ${isLocked ? 'app-content--lock' : ''}`}>
                <ProductGroups groups={window.APP_SETTINGS?.productGroups} />
              </section>
            </div>

            <div id="summary" className={isRightColumnOpen ? "col-12 col-xl-4 app-sidebar app-sidebar--right-side" : "col-12 col-xl-4 app-sidebar"}>
              <Summary name="test" />
            </div>

            <div className={isSummaryOpen ? "app-sidebar__mobile-window summary-window-active" : "app-sidebar__mobile-window"}>
              <div className="app-sidebar__mobile-window__title">
                <p>ORDER SUMMARY</p>
                <button className="app-header--summary app-header--summary--active" onClick={() => setSummaryOpen(!isSummaryOpen)}>{/*Close*/}</button>
              </div>
              <SummarySmall name="test" />
              <a onClick={() => setSummaryOpen(!isSummaryOpen)} className="app-sidebar__mobile-window__order-btn" href="/#summary">ORDER NOW</a>
            </div>

          </div>
        </PriceTypeContext.Provider>
      </SummaryContext.Provider>
    </div>
  );
}

export default () => (
  <OrderProvider>
    <App />
  </OrderProvider>
);
