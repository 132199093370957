import React, { useEffect, useState } from 'react';
import ProductWithVariants from './ProductWithVariants';

import './ProductGroup.scss';
import ProductVariant from './ProductVariant';

const ProductGroup = ({ group = {} }) => {
    const hasVariants = group?.variants?.length > 0;

    /* sprawdzanie czy ekran jest dotykowy */
    const isTouchDevice = () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }

    const [isTouchScreen, setTouchScreen] = useState(false);

    useEffect(() => {
        setTouchScreen(isTouchDevice())
    }, []);


    return (
        <section className="product-group">
            <h2>{group.name}</h2>
            {hasVariants ?
                <>
                    <div className="product-group__variant product-group__variant--names">
                        <div className="product-group__products">
                            <div className="product-variant__wrapper"></div>
                            {group.variants.map((v, idx) => {
                                return <div className="product-variant__wrapper">{v}</div>
                            })}
                        </div>
                    </div>
                    {group.products.map((p, i) => <ProductWithVariants key={i} product={p} isTouchScreen={isTouchScreen} variants={group.variants} />)}
                </>
                :
                <>
                    {isTouchScreen ?
                        <div className="product-group__products product-group__touch-screen-scroll">
                            {group.products.map((p, i) => <ProductVariant key={i} variant={p} />)}
                        </div>
                        :
                        <div className="product-group__products">
                            {group.products.map((p, i) => <ProductVariant key={i} variant={p} />)}
                        </div>
                    }
                </>
            }
        </section>
    );
}


export default ProductGroup