import React, { useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import './SuccessModal.scss';

const SuccessModal = ({ modalData }) => {

    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        setOpen(modalData?.message?.length > 0);
    }, [modalData]);

    return ReactDOM.createPortal((
        <div className={`modal ${isOpen && 'show'}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <button className="closeBtn" onClick={() => setOpen(false)}>
                        <svg width="28px" height="28px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000"><line x1="16" y1="16" x2="48" y2="48" /><line x1="48" y1="16" x2="16" y2="48" /></svg>
                    </button>
                    <p className="my-3 text-center">
                        <span dangerouslySetInnerHTML={{ __html: modalData.message }} />
                    </p>
                    <button className="btn btn-primary mt-3" onClick={() => setOpen(false)}>
                        OK!
                    </button>
                </div>
            </div>
        </div>
    ), document.body);

};

export default SuccessModal;