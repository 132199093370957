import React from 'react';
import ProductVariant from './ProductVariant';

const ProductWithVariants = ({ product = {}, variants = [], isTouchScreen }) => {
    return (
        <div className="product-group__variant">
            <div className="product-group__products">
                <div className="product-variant__wrapper product-variant__wrapper--category-name" dangerouslySetInnerHTML={{ __html: product.name.replace(',', '<br />') }} />
                {isTouchScreen ?
                    <div className="product-group__touch-screen-scroll">
                        {variants.map((v, idx) => {
                            return product.variants[v] ? <ProductVariant key={idx} variant={product.variants[v]} /> : null
                        })}
                    </div>
                    :
                    <>
                        {variants.map((v, idx) => {
                            return product.variants[v] ? <ProductVariant key={idx} variant={product.variants[v]} /> : null
                        })}
                    </>
                }
            </div>
        </div>
    );
}


export default ProductWithVariants