import React, { useContext, useEffect, useState } from 'react';
import { useOrderContext } from '../context/OrderContext';
import { SummaryContext } from '../context/SummaryContext';
import { PriceTypeContext } from '../context/PriceTypeContext';

import formatPrice from '../utils/formatPrice';

import './ProductVariant.scss';

const ProductVariant = ({ variant = {} }) => {
    const { quantities, setQuantity } = useOrderContext();
    const { isRightColumnOpen, setRightColumnOpen } = useContext(SummaryContext)
    const { priceType, setPriceType } = useContext(PriceTypeContext)
    const currentValue = quantities[variant.SKU] ?? '0';
    const { quantitiesCart, getCart } = useOrderContext()

    const cart = getCart();

    const [hasInputFocus, setInputFocus] = useState(false);

    return (
        <div className="product-variant__wrapper">
            <div className={"product-variant " + (quantities[variant.SKU] > 0 ? 'product-variant--active' : '')}>
                {variant.variant && <div className="product-variant--name">{variant.variant}</div>}
                <img loading="lazy" className={`product-variant--picture ${variant.picture ? '' : 'product-variant--picture--placeholder'}`} src={variant.picture || window.APP_SETTINGS?.portalSettings?.LOGO} alt={variant.sku} />
                <br />
                <div className="product-variant--sku">SKU: {variant.SKU}</div>
                <div className="product-variant--price">
                    {variant.prices?.rrp && formatPrice(variant.prices.rrp)}
                    {variant.prices?.rrp && priceType !== "rrp" && <> / </>}
                    {priceType !== "rrp" && formatPrice(variant.prices[priceType])}
                </div>
                <div className="product-variant--quantity">
                    <div className="input-group input-group-sm">
                        <button onClick={e => setQuantity(variant.SKU, currentValue - 1)} className="btn btn-light">-</button>
                        <input
                            value={hasInputFocus && currentValue == '0' ? '' : currentValue}
                            onChange={(e) => setQuantity(variant.SKU, e.target.value)}
                            onFocus={() => setInputFocus(true)}
                            onBlur={() => setInputFocus(false)}
                            className="form-control text-center" type="number" name={variant.SKU} />
                        <button onClick={() => (setQuantity(variant.SKU, currentValue + 1), cart.pcs < 1 && setRightColumnOpen(true))} className="btn btn-light">+</button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ProductVariant