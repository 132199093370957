import React from 'react';
import ProductGroup from './ProductGroup';

import './ProductGroups.scss';

const ProductGroups = ({ groups = [] }) => {

    return (
        <div className="product-groups">
            {groups.map((g, index) => {
                return <ProductGroup key={index} group={g} />
            })}
        </div>
    );
}


export default ProductGroups