import axios from "axios";
import React, { useEffect, useState, useRef } from "react"

const OrderContext = React.createContext({ quantities: {}, data: {} });

const getInitialState = (name, defalutValue = {}) => {
    const value = localStorage?.getItem(name)
    return value ? JSON.parse(value) : defalutValue
}

const OrderProvider = ({ children, initialCount = 0 }) => {
    const [quantities, setQuantities] = React.useState(getInitialState('quantities'))
    const [data, setData] = React.useState(getInitialState('data'))
    let variants = {};

    const [isLocked, setLock] = useState(false);
    const [cartDiscount, setCartDiscount] = useState("rrp");
    const [isAutocomplete, setAutocomplete] = useState(false);

    const autocompleteController = useRef(new AbortController());

    useEffect(() => {
        localStorage?.setItem('quantities', JSON.stringify(quantities));
        if (Object.keys(quantities).length <= 0) {
            setAutocomplete(false);
            setData({})
        }
    }, [quantities])

    useEffect(() => {
        localStorage?.setItem('data', JSON.stringify(data))
    }, [data]);

    const clearAll = () => {
        setQuantities({});
        setData({});
        setAutocomplete(false);
    }

    const setQuantity = (sku, count) => {
        const newQuantities = { ...quantities };

        if (count < 0) {
            // ignoruj ujemne
        } else if (count == 0) {
            delete newQuantities[sku];
        } else {
            newQuantities[sku] = parseInt(count);
        }

        return setQuantities(newQuantities);
    }
    const setField = (field, value) => {
        const newData = { ...data };
        newData[field] = value;
        return setData(newData);
    }

    const getMaxClientDiscount = () => {
        if (window?.APP_SETTINGS?.portalSettings?.DISCOUNT_LEVELS?.map) {
            const discounts = window.APP_SETTINGS.portalSettings.DISCOUNT_LEVELS.map((x) => parseFloat(x.discount));
            return Math.max(...discounts);
        }
        return 0;
    }

    const getWholesale = (price) => {
        const vat = (parseFloat(window.APP_SETTINGS?.portalSettings?.VAT || 0) + 100) / 100;
        return Math.round((price / vat) * 100) / 100;
    }

    window.APP_SETTINGS.productGroups.map(g => {
        return g.products.map(p => {
            if (p.variants) {
                Object.entries(p.variants).map(([variantName, v], index) => {
                    v.wholesalePrice = getWholesale(v.price)
                    variants[v.SKU] = { ...v };
                    variants[v.SKU].variant = variantName;
                    variants[v.SKU].name = g.name;
                });
            } else {
                p.wholesalePrice = getWholesale(p.price)
                variants[p.SKU] = { ...p };
                variants[p.SKU].name = g.name;
                variants[p.SKU].wholesalePrice = getWholesale(p.price)
            }

        });
    });

    const getCart = () => {
        const MOQ = window.APP_SETTINGS?.portalSettings?.MOQ || 0;
        const DISCOUNT_LEVELS = window.APP_SETTINGS?.portalSettings?.DISCOUNT_LEVELS || [];
        let cart = {
            sum: 0,
            sumWholesale: 0,
            pcs: 0,
            discount: "",
            items: [],
        };

        cart.items = Object.entries(quantities).map(([sku, count], index) => {

            if (variants[sku]) {
                let v = variants[sku];
                cart.sum += count * v.price;
                cart.pcs += count;

                return {
                    lp: index + 1,
                    name: v.name,
                    variant: v.variant,
                    SKU: v.SKU,
                    count: count,
                    price: v.prices[cartDiscount],
                    value: count * v.prices.rrp,
                }
            }
        }).filter(n => n); // wyrzuca produkty nieistniejące

        DISCOUNT_LEVELS.map(({ minPrice, priceLevel }, i) => {
            if (cart.sum >= minPrice) {
                cart.discount = priceLevel;
                setCartDiscount(priceLevel);
                if (DISCOUNT_LEVELS[i + 1]) {
                    cart.nextDiscount = {
                        discount: DISCOUNT_LEVELS[i + 1].priceLevel,
                        level: DISCOUNT_LEVELS[i + 1].minPrice,
                        missing: DISCOUNT_LEVELS[i + 1].minPrice - cart.sum
                    }
                } else {
                    cart.nextDiscount = undefined;
                }
            }
        })
        cart.sumDiscounted = cart.sum * (100 - cart.discount) / 100;

        cart.items.map((item) => {
            item.wholesalePrice = getWholesale(item.price, cart.discount);
            item.wholesaleValue = Math.round((item.count * item.wholesalePrice) * 100) / 100;
            cart.sumWholesale += item.wholesaleValue;
        });

        if (MOQ > cart.sumWholesale) {
            cart.moq = {
                value: MOQ,
                missing: Math.round((MOQ - cart.sumWholesale) * 100) / 100,
            }
        }


        return cart;
    }

    const autocomplete = async (force = false) => {
        if (isAutocomplete && !force)
            return;

        setLock(true);

        autocompleteController.current.abort();
        autocompleteController.current = new AbortController();

        try {
            const { data: autocompleteData } = await axios.get('/request/autocomplete', {
                params: {
                    email: data.email,
                    phone: data.phone
                },
                signal: autocompleteController.current.signal
            });

            setData({
                ...data,
                email: data.email || autocompleteData.email,
                phone: (data.phone && data.phone.length) > 4 ? data.phone : autocompleteData.phone,
                billingAddress: data.billingAddress || autocompleteData.billing_address,
                deliveryAddress: data.deliveryAddress || autocompleteData.delivery_address
            });
            setAutocomplete(true);
        } catch {
        } finally {
            setLock(false);
        }
    };

    return (
        <OrderContext.Provider value={{ quantities, setQuantity, data, setField, getCart, clearAll, isLocked, setLock, autocomplete }}>
            {children}
        </OrderContext.Provider>
    )
}

const useOrderContext = () => {
    const context = React.useContext(OrderContext)

    if (context === undefined) {
        throw new Error('useOrderContext must be used within OrderProvider')
    }
    return context
}

export { OrderProvider as default, useOrderContext }